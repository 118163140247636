import {RouteRecordRaw} from 'vue-router';
import spinner from '../components/Spinner/SpinnerSon.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        components: {
            default: () => import('../components/Layouts/DefaultLayout.vue'),
            spinner: spinner,
        },
        children: [
            {
                path: '',
                component: () => import('../pages/Index.vue'),
            },
            {
                path: 'about',
                component: () => import('../pages/About/About.vue'),
            } 
        ]
    },
    {
        path: '/v1/get/weather/:cityName/:nation',
        components: {
            default: () => import('../components/Layouts/DefaultLayoutSon.vue'),
            spinner: spinner,
        },
        children: [
            {
                path: '',
                component: () => import('../pages/IndexSon.vue'),
            } 
        ] 
    },
    {
        path: '/v1/get/geoweather/:latitude/:longitude',
        components: {
            default: () => import('../components/Layouts/DefaultLayoutSon.vue'),
            spinner: spinner,
        },
        children: [
            {
                path: '',
                component: () => import('../pages/IndexSon.vue'),
            } 
        ] 
    }
];

 

export default routes;
