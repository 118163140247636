<template>
  <transition name="fade">  
      <div class="lds-facebook" v-if="isLoading">
        <div>
        </div>
        <div>
        </div>
        <div>
        </div> 
        <div>
        </div> 
        <div>
        </div>  
    </div>
  </transition>
</template>

<script lang="ts" setup>
import {computed,ref} from "vue";
import {useIndexStore} from "@/stores";

type EmailStatus = 'draft' | 'read' | 'unread';

interface SpinnerPropsInterface {
  small?: boolean;
  large?: boolean; 
  title?: EmailStatus;  
  easing: "ease-in" | "ease-out" | "ease-in-out";//옵션널
  title2?: String;  
} 
 
 
const props = withDefaults(defineProps<SpinnerPropsInterface>(), {
  small: false,
  large: true,
  title: 'draft',
  easing: 'ease-in',
});
 
 

const indexStore = useIndexStore();
const isLoading = computed(() => {
  return indexStore.getIsLoading; 
})

</script>

<style lang="scss" scoped>

@import "../../assets/scss/common/mixins";
 
.lds-facebook {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 47%;
    left: 47%
}
.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #a6a6a6;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: -26px;
    animation-delay: -0.48s;
}
.lds-facebook div:nth-child(2) {
    left: -10px;
    animation-delay: -0.36s;
}
.lds-facebook div:nth-child(3) {
    left: 6px;
    animation-delay: -0.24s;
}
.lds-facebook div:nth-child(4) {
    left: 22px;
    animation-delay: -0.12s;
}
.lds-facebook div:nth-child(5) {
    left: 38px;
    animation-delay: 0s;
}
 
@keyframes lds-facebook {
    0% {
        top: 6px;
        height: 51px;
    }
    50%, 100% {
        top: 19px;
        height: 25px;
    }
}

</style>
